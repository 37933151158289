export default function modalApi (){

    
    const modalWrapper = document.querySelector(".modal-guts");
    const modalAtracoes = document.querySelector('#modal-atracoes');

    const mainWrapper = document.querySelector('main');
    // modalAtracoes.style.height = '530px';

    // REMOVING PROGRAMACAO 
    const BACKUPHTML = `   <div class="programacao">
        <h2 class="title-programacao">programação</h2>


        <div class="wrapper-programacao">
            <div class="section-title">
            <div class="badge-wrapper bg-azul">
                <p class="badge"> <span>24 de setembro</span> • <span class="horario">15hoo</span></p>
            </div>
            </div>
        

            <div class="nome-evento-wrapper">
            <p class="confirmado">Participante confirmado para o evento</p>
            <p class="nome-evento">Nome do evento</p>
            </div>
        </div>
        
    </div>`

    function formatarData(data) {
        const meses = [
          "JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO",
          "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"
        ];
      
        // Separar a data da hora usando o espaço como delimitador
        const [dataPart, horaPart] = data.split(' ');
      
        // Separar o dia, mês e ano
        const [dia, mes, ano] = dataPart.split('/');
      
        // Obter o nome do mês formatado
        const nomeMes = meses[parseInt(mes) - 1];
      
        // Montar a data formatada
        const dataFormatada = `${dia} DE ${nomeMes}`;
      
        return {
            data: dataFormatada,
            hora: horaPart
        };
      }
    

    function renderModal(atracao){

    


        function compararEventos(a, b) {
            const dataHoraA = new Date(a.data_hora);
            const dataHoraB = new Date(b.data_hora);
            return dataHoraA - dataHoraB;
        }
        const programacaoOrdenada = atracao?.programacao?.sort(compararEventos);

        const modalHTML = `
            <div class="header-wrapper-modal">
        

                <div class="atracao-img-modal">
                    <div class="card-evento-img">
                        <img src="${atracao.imagem}" alt="">
                    </div>
                    <div  class="content">
                        <h2 class="card-evento-title">${atracao.nome}</h2>
                        <p class="card-evento-role">${atracao.funcao}</p>         
                    </div>
                </div>
            
                <div class="infos-tragetoria">
                    <h2>minha trajetória</h2>
                    <div class="paragraphs-content">         
                        <p>
                            ${atracao.descricao.replace(/\r\n/g, "<br>")}
                        </p>

                   
                    </div>
                </div>
            </div>

            <div class="programacao">
                <h2 class="title-programacao">programação</h2>
                <p class="confirmado">Participante confirmado para o evento</p>
                ${programacaoOrdenada &&
                    programacaoOrdenada.map(prog => {  
                        const {data , hora } = formatarData(prog.data_hora)
                        console.log(data , hora)
                        return `
                        <div class="wrapper-programacao">
                            <div class="section-title">
                                <div class="badge-wrapper bg-azul">
                                <p class="badge"> <span>${data}</span> • <span class="horario">${hora}</span></p>
                                </div>
                            </div>
                            
                
                            <div class="nome-evento-wrapper">
                               
                                <p class="nome-evento">${prog.titulo}</p>
                            </div>
                        </div>
                         
                        `
                    }).join('')
                }                                          
            </div>
          
      


            <div class="atracao-img-modal atracao-img-modal-mobile ">
                <div class="card-evento-img">
                <img src="${atracao.imagem}" alt="">
                </div>
                <div  class="content">
                <h2 class="card-evento-title">${atracao.nome}</h2>
                <p class="card-evento-role">${atracao.funcao}</p>         
                </div>
            </div>
        `


       
        modalWrapper.innerHTML = modalHTML
    }

    function getAtracao(id){
   
        const url = `/home/api/atracoes/${id}`
        axios.post(url)
            .then((response) => {

             
                if(response.data){
                    renderModal(response.data)
                }
            })
            .catch((response) => {
                console.log(response)
               
            });
    }



    mainWrapper.addEventListener('click', (e) => {
        const target = e.target.closest('.open-modal-atracao');
        
        if(!target) return
        const card = target.closest('.card-evento');
        const id = card.dataset.id
       
        getAtracao(id); 
    })


    // openButton.forEach(atracao =>{

    //     atracao.addEventListener('click', (e) => {
    //         const target = e.target.closest('.card-evento');
    //         if(!target) return
    //         const id = target.dataset.id
           

    //         getAtracao(id);
    //     })
    // })

    

    

    
}